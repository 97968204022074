.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .recharts-responsive-container */
.echarts-for-react {
  flex: 1;
  height: 400px !important;
  border-radius: 6px;
  /* border: 1px solid #23334C; */
  background: rgba(24, 31, 43, 0.5);
  margin-right: 25px;
  border: 1px double transparent;
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#0d1f2f, #0d1f2f),linear-gradient(180deg, #353848, #6A708B)!important;
  background-origin: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
